import React from 'react'

const OurProject = () => {
  return (
    <div>
        <section className="project-area  section-padding30">
            <div className="container">
               <div className="project-heading mb-35">
                    <div className="row align-items-end">
                        <div className="col-lg-6">
         
                            <div className="section-tittle section-tittle3">
                                <div className="front-text">
                                    <h2 className="">Our Projects</h2>
                                </div>
                                <span className="back-text">Gellary</span>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="properties__button">
                                                                         
                                <nav> 
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="false">Show  all </a>
                                        <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-graphic" role="tab" aria-controls="nav-graphic" aria-selected="false">Graphic</a>
                                        <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-dom-graphic" role="tab" aria-controls="nav-dom-graphic" aria-selected="false">Dom Graphic</a>
                                        <a className="nav-item nav-link" id="nav-last-tab" data-toggle="tab" href="#nav-emblem" role="tab" aria-controls="nav-emblem" aria-selected="false">Soft Emblem</a>
                                        <a className="nav-item nav-link" id="nav-technology" data-toggle="tab" href="#nav-advertise" role="tab" aria-controls="nav-advertise" aria-selected="false">Advertise</a>
                                    </div>
                                </nav>
                                
                            </div>
                        </div>
                    </div>
               </div>
                <div className="row">
                    <div className="col-12">
   
                        <div className="tab-content active" id="nav-tabContent">
       
                            <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">           
                                <div className="project-caption">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project1.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                    <h4><a href="project_details.html">Letter Sign Astra Infra</a></h4>
                                                    <h4><a href="project_details.html">Media Advertise Outdoor</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project2.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Letter Sign Garda Astra</a></h4>
                                                    <h4><a href="project_details.html">Media Advertise Outdoor</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project3.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Interior Asuransi Astra</a></h4>
                                                    <h4><a href="project_details.html">Media Advertise Indoor</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project4.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Interior HO AHM</a></h4>
                                                    <h4><a href="project_details.html">Interior & Exterior</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project5.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Astra Infra | Toll Road</a></h4>
                                                    <h4><a href="project_details.html">Trafic Sign</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project6.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Letter Sign Honda Dealer</a></h4>
                                                    <h4><a href="project_details.html">Media Advertise Outdoor</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
         
                            <div className="tab-pane fade" id="nav-graphic" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <div className="project-caption">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project5.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project6.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project1.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project2.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project3.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project4.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <div className="tab-pane fade" id="nav-dom-graphic" role="tabpanel" aria-labelledby="nav-contact-tab">
                                <div className="project-caption">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project3.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project4.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project1.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project2.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project5.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project6.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-emblem" role="tabpanel" aria-labelledby="nav-last-tab">
                                <div className="project-caption">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project1.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project2.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project3.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project4.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project5.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project6.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Floride Chemicals</a></h4>
                                                    <h4><a href="project_details.html">Factory</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-advertise" role="tabpanel" aria-labelledby="nav-technology">
                            <div className="project-caption">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project1.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                    <h4><a href="project_details.html">Letter Sign Astra Infra</a></h4>
                                                    <h4><a href="project_details.html">Media Advertise Outdoor</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project2.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Letter Sign Garda Astra</a></h4>
                                                    <h4><a href="project_details.html">Media Advertise Outdoor</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project3.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Interior Asuransi Astra</a></h4>
                                                    <h4><a href="project_details.html">Media Advertise Indoor</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project4.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Interior HO AHM</a></h4>
                                                    <h4><a href="project_details.html">Interior & Exterior</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project5.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Astra Infra | Toll Road</a></h4>
                                                    <h4><a href="project_details.html">Trafic Sign</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-project mb-30">
                                                <div className="project-img">
                                                    <img src="assets/img/gallery/project6.png" alt=""/>
                                                </div>
                                                <div className="project-cap">
                                                    <a href="project_details.html" className="plus-btn"><i className="ti-plus"></i></a>
                                                   <h4><a href="project_details.html">Letter Sign Honda Dealer</a></h4>
                                                    <h4><a href="project_details.html">Media Advertise Outdoor</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default OurProject