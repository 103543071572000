import React from 'react'

const Footer = () => {
  return (
    <div>
        <footer>
        <div className="footer-main">
                <div className="footer-area footer-padding">
                    <div className="container">
                        <div className="row  justify-content-between">
                            <div className="col-lg-4 col-md-4 col-sm-8">
                                <div className="single-footer-caption mb-30">
                                    <div className="footer-logo">
                                        <a href="index.html"><img src="assets/img/logo/logo2_footer.png" alt=""/></a>
                                    </div>
                                    <div className="footer-tittle">
                                        <div className="footer-pera">
                                            <p className="info1">Ultimately, a company's graphic design should be engaging, memorable, and effective at communicating the desired message to its target audience.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-5">
                                <div className="single-footer-caption mb-50">
                                    <div className="footer-tittle">
                                        <h4>Quick Links</h4>
                                        <ul>
                                            <li><a href="#">About</a></li>
                                            <li><a href="#">Services</a></li>
                                            <li><a href="#">Projects</a></li>
                                            <li><a href="#">Contact Us</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-7">
                                <div className="single-footer-caption mb-50">
                                    <div className="footer-tittle">
                                        <h4>Contact</h4>
                                        <div className="footer-pera">
                                            <p className="info1">Cikarang Industrial Estate, Jababeka 1 Block U 8D, U 8C & U 7A, Cikarang, Harja Mekar, Cikarang Utara, Harja Mekar, Cikarang Utara, Bekasi Regency, West Java 17530, Indonesia</p>
                                        </div>
                                        <ul>
                                            <li><a href="#">Phone 1: +(62) 893 471 4</a></li>
                                            <li><a href="#">pHONE 2: +(62) 891 110 14</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-8">
                                <div className="single-footer-caption mb-50">
                                    {/*<!-- Form -->*/}
                                    <div className="footer-form">
                                        <div id="mc_embed_signup">
                                            <form target="_blank" action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01" method="get" className="subscribe_form relative mail_part" novalidate="true">
                                                <input type="email" name="EMAIL" id="newsletter-form-email" placeholder=" Email Address " className="placeholder hide-on-focus" onfocus="this.placeholder = ''" onblur="this.placeholder = ' Email Address '"/>
                                                <div className="form-icon">
                                                    <button type="submit" name="submit" id="newsletter-submit" className="email_icon newsletter-submit button-contactForm">
                                                        SIGN UP
                                                    </button>
                                                </div>
                                                <div className="mt-10 info"></div>
                                            </form>
                                        </div>
                                    </div>
                                    {/*<!-- Map -->*/}
                                    <div className="map-footer">
                                        <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=PT. Grafindo Mitrasemesta harja mekar&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-xl-12 ">
                                <div className="footer-copy-right">
                                    <p>Created at 2023</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </footer>
    </div>
  )
}

export default Footer