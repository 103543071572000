import React from 'react'

const Services = () => {
  return (

        <div className="services-area1 section-padding30">
            <div className="container">

                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-tittle mb-55">
                            <div className="front-text">
                                <h2 className="">Our Services</h2>
                            </div>
                            <span className="back-text">Services</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="single-service-cap mb-30">
                            <div className="service-img">
                                <img src="assets/img/service/servicess1.png" alt=""/>
                            </div>
                            <div className="service-cap">
                                <h4><a href="services_details.html">Graphic</a></h4>
                                <p>- Pressure Sensitive Adheshive Sticker </p>
                                <p>- Automotive Usage Purpose</p>
                                <p>- Coated for better durability and Ensure the sticker lasts as long as possible</p>
                                <a href="services_details.html" className="more-btn">Read More <i className="ti-plus"></i></a>
                            </div>
                            <div className="service-icon">
                                <img src="assets/img/icon/services_icon1.png" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="single-service-cap mb-30">
                            <div className="service-img">
                                <img src="assets/img/service/servicess2.png" alt=""/>
                            </div>
                            <div className="service-cap">
                                <h4><a href="services_details.html">Resin Dom Graphic</a></h4>
                                <p>- Pressure Sensitive Adhesive Sticker</p>
                                <p>- Automotive Usage Purpose</p>
                                <p>- Coated for better durability & mercury free resin, for 3D effect</p>
                                <a href="services_details.html" className="more-btn">Read More <i className="ti-plus"></i></a>
                            </div>
                            <div className="service-icon">
                                <img src="assets/img/icon/services_icon1.png" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="single-service-cap mb-30">
                            <div className="service-img">
                                <img src="assets/img/service/servicess3.png" alt=""/>
                            </div>
                            <div className="service-cap">
                                <h4><a href="services_details.htmlaa">Label & Soft Emblem</a></h4>
                                <p>- Pressure Sensitive Adheshive Sticker </p>
                                <p>- High Durability Purpose</p>
                                <p>- Laminated with special film for comply with extreme condition</p>
                                <a href="services_details.html" className="more-btn">Read More <i className="ti-plus"></i></a>
                            </div>
                            <div className="service-icon">
                                <img src="assets/img/icon/services_icon1.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
  )
}

export default Services