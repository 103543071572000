import React from 'react';
import Navbar from './components/navbar/Navbar';
import Hero from './components/hero/Hero';
import Services from './components/sevices/Services';
import About from './components/about/About';
import OurProject from './components/project/OurProject';
import Footer from './components/footer/Footer';

function App() {
  return (
    <div>
        <Navbar/>
        <Hero/>
        <Services/>
        <About/>
        <OurProject/>    
        <Footer/>
    </div>
  );
}

export default App;
