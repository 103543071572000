import React from 'react'

const About = () => {
  return (
    <div>
        <section className="support-company-area fix pt-10">
            <div className="support-wrapper align-items-end">
                <div className="left-content">

                    <div className="section-tittle section-tittle2 mb-55">
                        <div className="front-text">
                            <h2 className="">Who we are</h2>
                        </div>
                        <span className="back-text">About us</span>
                    </div>
                    <div className="support-caption">
                        <p className="pera-top">We are OEM Graphic, Emblem and Soft Emblem Automotive Company at Cikarang Jababeka Industrial</p>
                        <p>PT. Grafindo Mitrasemesta is a local, domestic company that is engaged and focused on providing services for the needs of Automotive Graphic Stickers, outdoor and indoor advertising media. As technology advances as well as the needs of consumers who always follow the modern market, we are always ready to provide maximum contribution and service by facilitating our machine equipment and systems to the fullest, in order to be able to provide the highest quality products that we produce according to consumer expectations.</p>
                        <a href="about.html" className="btn red-btn2">read more</a>
                    </div>
                </div>
                <div className="right-content">
         
                    <div className="right-img">
                        <img src="assets/img/gallery/safe_in.png" alt=""/>
                    </div>
                    <div className="support-img-cap text-center">
                        <span>1994</span>
                        <p>Since</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default About