import React from 'react'
import './Hero.css';

const Hero = () => {
  return (
        <div className="slider-area ">
            <div className="slider-active">
                <div className="single-slider  hero-overly slider-height d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-11">
                                <div className="hero__caption">
                                    <div className="hero-text1">
                                        <span data-animation="fadeInUp" data-delay=".3s">OEM GRAPHIC AUTOMOTIVE</span>
                                    </div>
                                    <h1 data-animation="fadeInUp" data-delay=".5s">grafindo</h1>
                                    <div className="stock-text" data-animation="fadeInUp" data-delay=".8s">
                                        <h2>mitrasemesta</h2>
                                        <h2>mitrasemesta</h2>
                                    </div>
                                    <div className="hero-text2 mt-110" data-animation="fadeInUp" data-delay=".9s">
                                       <span><a href="services.html">Our Services</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
  )
}

export default Hero